/*style the box*/
.gm-style .gm-style-iw {
  background-color: #3c61ad !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  min-height: 180px;
  padding-top: 10px;
}

/*style the p tag*/
.gm-style .gm-style-iw #google-popup p {
  padding: 10px;
}

/*style the arrow*/
.gm-style div div div div div div div div {
  background-color: #3c61ad !important;
  padding: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  top: 0;
  color: #fff;
  font-size: 16px;
}

/*style the link*/
.gm-style div div div div div div div div a {
  color: #f1f1f1;
  font-weight: bold;
}
