.box p {
  text-align: justify;
  margin-top: 17px;
  margin-bottom: 0px;
}
.box select {
  background-color: grey;
  color: white;
  padding: 12px;
  width: 250px;
  border: none;
  font-size: 13px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
  padding: 30px;
}
.LinkUtility {
  font-size: 15pt;
  font-style: Bold;
  margin-bottom: 10px;
}
.pageSelection {
  margin-bottom: 20px;
}
