.HomeMap {
  position: relative;

  /*The background extends to the outside edge of the padding. No background is drawn beneath the border.*/
  margin-bottom: 30px;
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.containerHeader {
  margin-top: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header1 {
  margin-bottom: 2vh;
  animation: color-change 10s infinite;
}
.pOpeningText {
  margin-top: 2vh;
}
@keyframes color-change {
  0% {
    color: rgb(148, 218, 250);
  }
  10% {
    color: rgb(112, 208, 253);
  }
  20% {
    color: rgb(77, 195, 250);
  }
  30% {
    color: rgb(50, 184, 247);
  }
  40% {
    color: rgb(4, 170, 247);
  }
  50% {
    color: rgb(13, 111, 156);
  }
  60% {
    color: rgb(4, 170, 247);
  }
  70% {
    color: rgb(50, 184, 247);
  }
  80% {
    color: rgb(77, 195, 250);
  }
  90% {
    color: rgb(112, 208, 253);
  }
  100% {
    color: rgb(148, 218, 250);
  }
}

.textbox1 {
  width: 35vw;
  height: 35vh;
  background: #e6e6e6;
  border-radius: 8px;
  box-shadow: 0 0 40px -10px #000;
  margin: calc(20vh - 150px) auto;
  padding: 20px 30px;
  max-width: calc(100vw - 40px);
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  display: inline-block;
}

.smallFootnote {
  text-align: center;
  font-size: 8pt;
}

.SearchHome {
  margin-top: 3vh;
  margin-bottom: 35vh;
}

.aboutHeader {
  font-size: 12;
}
.searchh2 {
  text-align: center;
}

.aboutDiv {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  max-width: 35vw;
}

.errorDiv {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.errorH1 {
  color: rgb(194, 44, 44);
  margin-bottom: 3pt;
}
.errorL {
  font-size: 34pt;
  font-style: bold;
  color: red;
}

.indent {
  padding-left: 2em;
}
