.bodyInput {
  background: #59abe3;
  margin: 0;
}

.formInput {
  width: 340px;
  height: 480px;
  background: #e6e6e6;
  border-radius: 8px;
  box-shadow: 0 0 40px -10px #000;
  margin: calc(20vh - 150px) auto;
  padding: 20px 30px;
  max-width: calc(100vw - 40px);
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  display: inline-block;
}
.h2Input {
  margin: 10px 0;
  padding-bottom: 10px;
  width: 180px;
  color: #78788c;
  border-bottom: 3px solid #78788c;
}

.inputBox {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: none;
  outline: none;
  resize: none;
  border: 0;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s;
  border-bottom: 2px solid #bebed2;
}
.inputBox:focus {
  border-bottom: 2px solid #78788c;
}
.pg:before {
  content: attr(type);
  display: block;
  margin: 28px 0 0;
  font-size: 14px;
  color: #5a5a5a;
}
.buttonInput {
  float: right;
  padding: 8px 12px;
  margin: 8px 0 0;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #78788c;
  background: #78788c;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
}
.buttonInput:hover {
  border: 2px solid black;
  background: black;
  color: #fff;
}
.divInput {
  content: "Hi";
  position: absolute;
  bottom: -15px;
  right: -20px;
  background: #50505a;
  color: #fff;
  width: 320px;
  padding: 16px 4px 16px 0;
  border-radius: 6px;
  font-size: 13px;
  box-shadow: 10px 10px 40px -14px #000;
}
.spanInput {
  margin: 0 5px 0 15px;
}

.align {
  width: 40%;
  margin-right: 20%;
  display: inline-block;
}
