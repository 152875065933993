.formSearch {
  position: absolute;

  left: 50%;
}
.buttonSearch[type="search"] {
  border: solid 3px #fff;
  box-sizing: border-box;
  font-size: 2.2em;
  text-align: center;
  height: 2em;
  margin-left: -15vw;
  outline: solid #fc0 0;
  padding: 0.5em;
  transition: all 2s ease-in;
  width: 30vw;

  z-index: 1;
}
.buttonSearch[type="search"]:focus {
  border: solid 3px #09f;
}

.noResults {
  justify-content: "center";
  align-self: center;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 42%);
}
.searchResults {
  justify-content: "center";
  align-items: center;
  position: absolute;
  display: grid;
  left: 50%;
  transform: translate(-50%, 42%);
}

.searchButton1 {
  grid-row: 1;
  grid-column: 1;
  border: none;
  background-color: transparent;
  color: rgb(35, 120, 201);
  text-underline-position: auto;
  margin-top: 9px;
}
.searchButton2 {
  grid-row: 2;
  grid-column: 1;
  border: none;
  background-color: transparent;
  color: rgb(35, 120, 201);
  text-underline-position: auto;
  margin-top: 9px;
}

.searchButton3 {
  grid-row: 3;
  grid-column: 1;
  border: none;
  background-color: transparent;
  color: rgb(35, 120, 201);
  text-underline-position: auto;
  margin-top: 9px;
}

.searchButton4 {
  grid-row: 4;
  grid-column: 1;
  border: none;
  background-color: transparent;
  color: rgb(35, 120, 201);
  text-underline-position: auto;
  margin-top: 9px;
}
.searchButton5 {
  grid-row: 5;
  grid-column: 1;
  border: none;
  background-color: transparent;
  color: rgb(35, 120, 201);
  text-underline-position: auto;
  margin-top: 9px;
}
.searchButton6 {
  grid-row: 6;
  grid-column: 1;
  border: none;
  background-color: transparent;
  color: rgb(35, 120, 201);
  text-underline-position: auto;
  margin-top: 9px;
}
