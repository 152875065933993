.buttonSubmit {
  padding: 8px 12px;
  margin: 8px 0 0;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #78788c;
  background: #78788c;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
}
.buttonSubmit:hover {
  border: 2px solid black;
  background: black;
  color: #fff;
}
.estimationDiv {
  left: 75%;
  position: absolute;
  transform: translate(-75%);
  text-align: center;
}

.box p {
  display: inline-block;
  text-align: justify;
  margin-bottom: 0px;
}
.box select {
  background-color: grey;
  color: white;
  padding: 12px;
  width: 250px;
  border: none;
  font-size: 13px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
  padding: 30px;
}
.centerDiv {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 6px;
}
.formControl {
  width: 300px;
}
.grid-container {
  display: grid;
  grid-template-columns: 25vw 25vw 25vw 25vw;
  grid-template-rows: auto;
  grid-template-areas:
    ". right left ."
    ". results results .";
}

.rightSide {
  grid-area: right;
}

.leftSide {
  grid-area: left;
}
.lastSection {
  text-align: center;
  position: absolute;
  left: 50vw;
  transform: translate(-50%, 0%);
  margin-top: 8px;
}
.topHeader {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
}
.submitButton {
  margin: auto;
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-bottom: 15px;
}

.result {
  font-size: 21px;
}
.centerme {
  width: 100%;
  margin: auto;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
}
.grid-container {
  margin: auto;
  width: 50%;
  text-align: center;
}
.grid-child {
  display: inline-block;
  width: 100%;
  margin: 0 4px;
}
.buffer {
  margin-bottom: 60px;
}
.ResultBox {
  margin-bottom: 6px;
}
